<template>
  <div>
    <v-col>
      <v-btn
        v-if="obsTemplateSelected"
        class="secondary"
        @click="showConfDialog = true"
      >
        Agregar configuración
      </v-btn>
      <v-dialog
        v-model="showConfDialog"
        transition="dialog-transition"
        max-width="1000"
      >
        <ModalConfiguracionObs
          :obsProvider="obsTemplateSelected?.id"
          @reloadList="getAgreementPresentations(currentAgreement.id)"
          @close="showConfDialog = false"
        />
      </v-dialog>
    </v-col>
    <v-col>
      <p class="mb-0 primary--text">{{ subtitle }}</p>
    </v-col>
    <v-col cols="12" md="12" class="mb-6">
      <data-table-component
        v-models:select="presentationsFilters.per_page"
        v-models:pagina="presentationsFilters.page"
        :headers="headers"
        :items="agreementPresentations"
        @paginar="paginate"
        :total_registros="presentationsFilters.total_rows"
      >
        <template v-slot:[`item.obs`]="{ item }">
          {{ item.codigo }} - {{ item.nombre_obs }}
        </template>
        <template v-slot:[`item.detalles`]="{ item }">
          <p class="mb-0">
            {{ item.detalle.map((col) => col.nombre).join(", ") }}
          </p>
        </template>
        <template v-slot:[`item.precio_unitario`]="{ item }">
          {{
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(item.precio_unitario)
          }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="secondary"
                icon
                @click="getPresentationAgreementPreview(item.id)"
                class="mr-2"
              >
                <v-icon>mdi-eye-outline</v-icon>
              </v-btn>
            </template>
            <span> Ver </span>
          </v-tooltip>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="secondary"
                @click="setConfigurationItem(item)"
                icon
                v-show="!onlyShow"
                class="mr-2"
              >
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
            </template>
            <span> Editar </span>
          </v-tooltip>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="secondary"
                icon
                v-show="!onlyShow"
                class="mr-2"
                @click="setDeleteItem(item.id)"
              >
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </template>
            <span> Eliminar </span>
          </v-tooltip>
        </template>
        <!-- <template v-slot:[`footer`]>
          <app-pagination
            :totalElements="presentationsFilters.total_rows"
            @cantidadPage="paginate"
            @changePage="paginate"
          />
        </template> -->
      </data-table-component>
      <!-- Dialogo de confirmación al eliminar un producto -->
      <ConfirmationDialog
        title="¿Desea remover la presentación del OBS seleccionado?"
        message="De confirmar la siguiente acción los cambios no serán reversibles"
        :show="showDelete"
        @confirm="deleteItem"
        @close="showDelete = false"
      />
      <!-- Modal para editar una presentación -->
      <v-dialog
        v-model="showUpdateDialog"
        transition="dialog-transition"
        max-width="1000"
      >
        <ModalConfiguracionObs
          @reloadList="getAgreementPresentations(currentAgreement.id)"
          isEditable
          :obsProvider="selectedPresentation.id_producto_proveedor"
          :presentation="selectedPresentation"
          @close="(showUpdateDialog = false), (selectedPresentation = {})"
        />
      </v-dialog>

      <!-- Vista previa del OBS -->

      <v-dialog v-model="showPreview" width="1000">
        <v-card>
          <div class="d-flex px-3 pt-3">
            <v-btn @click="showPreview = false" large icon
              ><v-icon>mdi-window-close</v-icon></v-btn
            >
          </div>
          <div class="px-15 pb-15">
            <ObsPreview :obs="obsPresentationPreview" />
          </div>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import ModalConfiguracionObs from "./ModalConfiguracionObsComponent.vue";
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";
import ObsPreview from "@/components/ObsPreviewComponent.vue";
import AppPagination from "@/components/AppPaginationComponent.vue";
import DataTableComponent from "../../../components/DataTableComponent.vue";

export default {
  name: "PresentationsTableComponent",
  components: {
    ModalConfiguracionObs,
    ConfirmationDialog,
    ObsPreview,
    AppPagination,
    DataTableComponent,
  },
  props: {
    obsTemplateSelected: {
      type: Object,
      default: () => {},
    },
    headers: {
      type: Array,
      default: () => [],
    },
    subtitle: {
      type: String,
      default: "",
    },
    onlyShow: {
      type: Boolean,
      default: false,
    },
    newProduct: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showDelete: false,
    showPreview: false,
    showConfDialog: false,
    selectedPresentation: {},
    showUpdateDialog: false,
    obsPresentationPreview: null,
    presentationToDelete: null,
  }),
  computed: {
    ...mapState("convenioMarcoProveedor", [
      "currentAgreement",
      "agreementPresentations",
      "presentationsFilters",
    ]),
  },
  methods: {
    ...mapActions("convenioMarcoProveedor", ["getAgreementPresentations"]),

    async deleteItem() {

      let response =
        await this.services.AgreementMarco.deleteConvenioOBSPresentation(
          this.presentationToDelete
        );
      if (response.status == 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "La presentación del OBS ha sido eliminada",
        });
        this.getAgreementPresentations(this.currentAgreement.id);
        this.showDelete = false;
      }

    },
    /**
     * Eliminar presentación de la tabla
     * @param {*} item presentación que sera eliminada
     */
    setDeleteItem(item) {
      this.showDelete = true;
      this.presentationToDelete = item;
    },
    /**
     * Seleccionar presentación de la tabla para editar
     * @param {*} item
     */
    setConfigurationItem(item) {
      this.selectedPresentation = item;
      this.showUpdateDialog = true;
    },

    async getPresentationAgreementPreview(id) {
      let response =
        await this.services.AgreementMarco.getConvenioOBSPresentationDetail(id);

      if (response.status == 200) {
        this.obsPresentationPreview = response?.data;
        this.showPreview = true;
      }
    },
    paginate(params) {
      this.presentationsFilters.page = params.pagina;
      this.presentationsFilters.per_page = params.cantidad_por_pagina;
      this.getAgreementPresentations(this.currentAgreement.id);
    },
  },
  async created() {
    if (this.agreementPresentations.length == 0) {
      await this.getAgreementPresentations(this.$route.params.id);
    }
  },
};
</script>
