<template>
  <div>
    <div width="100%">
      <ProductZoomer
        v-if="images.normal_size?.length > 0"
        :base-images="images"
        :base-zoomer-options="zoomerOptions"
      />
      <v-img width="400px" v-else src="../assets/img/no-image.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductCarouselComponent",
  props: {
    imagenes: Array,
  },
  data() {
    return {
      images: {},
      zoomerOptions: {
        zoomFactor: 2,
        pane: "pane",
        hoverDelay: 300,
        namespace: "zoomer-bottom",
        move_by_click: false,
        scroll_items: 0,
        choosed_thumb_border_color: "#111C4E",
        scroller_position: "bottom",
        zoomer_pane_position: "right",
      },
    };
  },
  watch: {
    imagenes(newValue) {
      this.images = {};
      if (newValue != []) {
        this.images = {
          thumbs: newValue,
          normal_size: newValue,
          large_size: newValue,
        };
        this.zoomerOptions.scroll_items = newValue.length;
        this.$forceUpdate();
      } else {
        this.images = {
          thumbs: [],
          normal_size: [],
          large_size: [],
        };
        this.zoomerOptions.scroll_items = 0;
      }
    },
  },
  created() {},
};
</script>

<style lang="scss">
#zoomer-bottom-pane-container img{
  width: auto !important;
}
.zoomer-bottom-base-container {
  // justify-content: center !important;
  height: 400px !important;
  width: 400px !important;
  .preview-box {
    width: 400px !important;
    height: 400px;
    object-fit: contain;
    background: white !important
  }
}

.pane-container{
  background: white !important;
}


div.thumb-list {
  display: flex !important;
  height: 90px !important;
  width: 400px !important;
  img {
    margin-right: 10px;
    width: 65px !important;
    height: 65px !important;
    object-fit: contain;
  }
}

img.zoomer-control {
  display: none !important;
}

.zoomer-bottom-base-container .preview-box {
  max-width: 300px !important;
}

@media (max-width: 1200px) {
  .zoomer-bottom-base-container .preview-box {
    max-width: 250px !important;
  }
}
</style>