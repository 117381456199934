<template>
  <v-card>
    <div class="d-flex px-3 pt-3">
      <v-btn @click="$emit('close'), clearConfiguracionObs()" large icon
        ><v-icon>mdi-window-close</v-icon></v-btn
      >
    </div>
    <v-card-title class="secondary--text">{{
      isEditable
        ? "Actualizar configuración de OBS"
        : "Agregar configuración de OBS"
    }}</v-card-title>
    <v-card-text class="mt-7">
      <v-row class="">
        <v-col cols="12">
          <v-autocomplete
            label="Configuración *"
            outlined
            :readonly="isEditable"
            style="width: 500px"
            color="secondary"
            :items="configurationsList"
            return-object
            item-text="nombre"
            item-value="id"
            v-model="configurationSelected"
            @change="setConfValues()"
            :error-messages="configurationErrors"
            @blur="$v.configurationSelected.$touch()"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12 mt-n6">
          <v-select
            :items="presentationsList"
            label="Unidad de venta"
            outlined
            readonly
            item-value="id"
            item-text="nombre"
            style="width: 500px"
            v-model="configurationSelected.id_presentacion"
          ></v-select>
        </v-col>
        <v-col class="mt-n6" lg="6" sm="6" cols="12">
          <p class="text-justify ml-2">
            A continuación escriba la característica del producto "Color rojo,
            resma, dimensiones..." y presione enter para agregarla al detalle
          </p>
        </v-col>
        <v-col cols="12 mt-n6 mb-6">
          <div class="chip-container" :class="isFocused && 'focusedBorder'">
            <div v-for="(chipText, index) in detailsList" :key="index">
              <v-chip class="ma-1" label color="deshabilitado">{{
                chipText.nombre
              }}</v-chip>
            </div>
            <v-text-field
              v-model="details"
              @focus="isFocused = true"
              @blur="isFocused = false"
              readonly
            />
          </div>
        </v-col>
        <v-col cols="12 mt-n6">
          <v-text-field
            label="Precio unitario*"
            outlined
            style="width: 500px"
            color="secondary"
            v-model="newConfiguration.precio_unitario"
            :error-messages="priceErrors"
            @input="$v.newConfiguration.precio_unitario.$touch()"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex img-container">
          <v-col cols="1">
            <div class="pa-4 ml-n5">
              <v-file-input
                disabled
                hide-input
                class="mt-9 pa-2"
                accept="image/png, image/jpeg, image/bmp"
                prepend-icon="mdi-camera"
                @change="onFileChange($event)"
                v-model="img"
              ></v-file-input>
            </div>
          </v-col>
          <v-col
            lg="11"
            sm="11"
            cols="10"
            class="mr-2 pa-2 align-self-center d-flex justify-space-around"
          >
            <div v-for="n in 4" :key="n">
              <v-img
                v-if="gallery[n - 1]"
                :src="gallery[n - 1].url"
                class="mx-auto"
                height="150"
                width="150"
              ></v-img>

              <v-icon v-else class="mr-2" size="150" color="secondary">
                mdi-file-image-plus-outline
              </v-icon>
            </div>
          </v-col>
        </v-col>
        <v-col cols="12 mt-n6">
          <v-text-field
            label="Cantidad con la que se participará en el convenio*"
            outlined
            style="width: 500px"
            color="secondary"
            :maxLength="12"
            v-model="newConfiguration.cantidad"
            :error-messages="quantityErrors"
            @input="$v.newConfiguration.cantidad.$touch()"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="pa-6">
      <v-btn
        large
        min-width="150"
        @click="savePresentationDetail()"
        class="secondary"
        >{{ isEditable ? "Actualizar" : "Agregar" }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import {
  required,
  decimal,
  numeric,
  helpers,
  requiredIf,
  minValue,
} from "vuelidate/lib/validators";

const priceDecimals = helpers.regex(
  "priceDecimals",
  /^[0-9]+([.][0-9]{1,2})?$/
);

export default {
  name: "ModalConfiguracionObsComponent",
  props: {
    obsProvider: {
      type: Number,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    presentation: {
      type: Object,
      default: () => {},
    },
  },
  validations: {
    configurationSelected: {
      required: requiredIf(function () {
        return this.isEditable ? false : true;
      }),
    },
    newConfiguration: {
      precio_unitario: { required, decimal, priceDecimals },
      cantidad: { required, numeric, minValue: minValue(1) },
    },
  },
  data: () => ({
    img: null,
    configurationSelected: {},
    configurationsList: [],
    newConfiguration: {
      precio_unitario: null,
      cantidad: null,
    },
    isFocused: false,
    presentationsList: [],
    details: null,
    detailsList: [],
    gallery: [],
  }),
  computed: {
    ...mapState("convenioMarcoProveedor", ["currentAgreement"]),
    configurationErrors() {
      const errors = [];
      if (!this.$v.configurationSelected.$dirty) return errors;
      !this.$v.configurationSelected.required &&
        errors.push("El campo configuración es obligatorio");
      return errors;
    },
    priceErrors() {
      const errors = [];
      if (!this.$v.newConfiguration.precio_unitario.$dirty) return errors;
      !this.$v.newConfiguration.precio_unitario.required &&
        errors.push("El campo precio unitario es obligatorio");
      !this.$v.newConfiguration.precio_unitario.decimal &&
        errors.push(
          "El campo precio unitario solo permite enteros y decimales"
        );
      !this.$v.newConfiguration.precio_unitario.priceDecimals &&
        errors.push(
          "No se permiten más de 2 dígitos después del punto decimal"
        );
      return errors;
    },
    quantityErrors() {
      const errors = [];
      if (!this.$v.newConfiguration.cantidad.$dirty) return errors;
      !this.$v.newConfiguration.cantidad.required &&
        errors.push("El campo cantidad es obligatorio");
      !this.$v.newConfiguration.cantidad.numeric &&
        errors.push("El campo cantidad solo números");
      !this.$v.newConfiguration.cantidad.minValue &&
        errors.push("No puede ingresar valores menores a 1");
      return errors;
    },
  },
  watch: {
    presentation() {
      if (Object.keys(this.presentation).length > 0) {
        this.getObsPresentations();
        this.setConfValuesUpdated();
      }
    },
    obsProvider() {
      if (this.obsProvider) {
        this.getObsPresentations();
      }
    },
  },
  methods: {
    // Actualiza la galeria de imagenes
    onFileChange(e) {
      if (e !== null) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.gallery.push(e.target.result);
        };
        reader.readAsDataURL(e);
        this.img = null;
      }
    },
    async getObsPresentations() {
      let response =
        await this.services.AgreementMarco.getConvenioOBSPresentationConfig(
          this.obsProvider
        );
      if (response.status == 200) {
        this.configurationsList = response?.data?.producto_presentacion;
      }
    },
    getPresentationImages() {
      const obs_foto_ids = this.configurationSelected.obs_fotos;
      let promises = [];
      this.gallery = [];
      for (let i = 0; i < obs_foto_ids.length; i++) {
        promises.push(
          this.services.AgreementMarco.getImagenesPresentacion(
            obs_foto_ids[i].id
          ).then((response) => {
            let files = response?.data;
            const reader = new FileReader();
            reader.onload = (files) => {
              this.gallery.push({
                url: files.target.result,
              });
            };
            reader.readAsDataURL(files);
          })
        );
      }
      Promise.all(promises);
    },
    setConfValues() {
      this.detailsList = this.configurationSelected?.etiquetas;
      this.newConfiguration.precio_unitario =
        this.configurationSelected?.precio_unitario;
      this.getPresentationImages();
    },
    setConfValuesUpdated() {
      this.configurationSelected.id =
        this.presentation.id_presentacion_obs_proveedor;
      this.newConfiguration.precio_unitario = this.presentation.precio_unitario;
      this.newConfiguration.cantidad = this.presentation.cantidad;
      this.configurationSelected.id_presentacion =
        this.presentation.id_presentacion;
      this.detailsList = this.presentation?.detalle;
      this.configurationSelected.id_presentation_provider =
        this.presentation.id;
      this.configurationSelected.obs_fotos = this.presentation.obs_foto;
      this.getPresentationImages();
    },
    async savePresentationDetail() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let params = {
          cantidad: Number(this.newConfiguration?.cantidad),
          precio_unitario: Number(this.newConfiguration?.precio_unitario),
        };
        let response = null;

        if (this.isEditable) {
          response =
            await this.services.AgreementMarco.updateConvenioOBSPresentation(
              this.configurationSelected.id_presentation_provider,
              params
            );
        } else {
          response =
            await this.services.AgreementMarco.postConvenioOBSPresentations(
              this.currentAgreement.id,
              this.configurationSelected.id,
              params
            );
        }

        if (response.status == 201 || response.status == 200) {
          if (response.data.creado || response.status == 200) {
            this.temporalAlert({
              show: true,
              type: "success",
              message: `Configuración del OBS ${
                this.isEditable ? "actualizada" : "guardada"
              }`,
            });
          } else {
            this.temporalAlert({
              show: true,
              type: "error",
              message:
                "No se puede agregar una presentación previamente registrada",
            });
          }
          this.configurationSelected = {};
          this.newConfiguration = {};
          this.$v.$reset();
          this.$emit("reloadList");
          this.$emit("close");
          this.gallery = [];
          this.detailsList = [];
        }
      }
    },
    async clearConfiguracionObs() {
      this.gallery = [];
      this.detailsList = [];
      this.configurationSelected = {};
      this.newConfiguration = {};
    },
    async getPresentations() {
      let response = await this.services.Presentations.getListPresentations();
      if (response.status == 200) {
        this.presentationsList = response?.data;
      }
    },
  },
  created() {
    this.getObsPresentations();
    this.getPresentations();
    if (this.isEditable) {
      this.setConfValuesUpdated();
    }
  },
};
</script>

<style lang="scss" scoped>
.img-container {
  margin-left: 10px;
  border-radius: 5px;
  margin-bottom: 40px;
  border: 1px solid #a1a1a1;
}

.chip-container {
  width: 500px;
  border: 1px solid #a1a1a1;
  min-height: 34px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  margin: 2px;
  padding: 2px;
  border-radius: 4px;
  align-items: center;
  :deep(.theme--light.v-text-field
      > .v-input__control
      > .v-input__slot:before) {
    border: none;
    outline: none;
  }
  :deep(.theme--light.v-text-field > .v-input__control > .v-input__slot:after) {
    border: none;
    outline: none;
  }

  :deep(.theme--light.v-text-field > .v-input__control > .v-input__slot) {
    flex: 1 1 auto;
    padding-left: 10px;
  }
}
.focusedBorder {
  border: 2px solid #111c4e;
}
</style>
