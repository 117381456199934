<template>
  <v-row>
    <v-col cols="12" md="7">
      <v-col v-if="title" cols="12" class="text-h6 secondary--text">
        <p class="mb-0">Vista previa del OBS</p>
      </v-col>
      <ProductCarousel :imagenes="obs_imagenes" />
    </v-col>
    <v-col cols="12" md="5">
      <v-col cols="12" class="text-h6 secondary--text">
        <p class="mb-0">{{ obs.titulo }}</p>
      </v-col>
      <v-col cols="12">
        <p>{{ obs.descripcion }}</p>
      </v-col>
      <v-col cols="12" class="caracteristica-producto-titulo">
        <p>OBS:</p>
      </v-col>
      <v-col
        cols="12"
        class="caracteristica-producto-subtitulo primary--text mb-6"
      >
        <p>{{ obs.codigo }} - {{ obs.nombre_obs }}</p>
      </v-col>
      <v-col cols="12" class="caracteristica-producto-titulo">
        <p>Presentación:</p>
      </v-col>
      <v-col
        cols="12"
        class="caracteristica-producto-subtitulo primary--text mb-6"
      >
        <p>{{ obs.nombre_presentacion }}</p>
      </v-col>
      <v-col cols="12" class="caracteristica-producto-titulo">
        <p>Unidad de venta:</p>
      </v-col>
      <v-col
        cols="12"
        class="caracteristica-producto-subtitulo primary--text mb-6"
      >
        <p>{{ obs.unidad_presentacion }}</p>
      </v-col>
      <v-col cols="12" class="caracteristica-producto-titulo">
        <p>Detalle:</p>
      </v-col>
      <v-col
        cols="12"
        class="caracteristica-producto-subtitulo primary--text mb-6"
      >
        <p v-for="(detail, index) in obs.detalle" :key="index">
          {{ detail.nombre }}
        </p>
      </v-col>

      <v-col cols="12" class="caracteristica-producto-titulo">
        <p>Precio:</p>
      </v-col>
      <v-col cols="12" class="caracteristica-producto-subtitulo primary--text">
        <p>
          {{
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(obs.precio_unitario)
          }}
        </p>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import ProductCarousel from "./ProductCarouselComponent.vue";

export default {
  name: "ObsPreviewComponent",
  components: {
    ProductCarousel,
  },
  props: {
    obs: {
      type: Object,
      // required: true,
      default() {
        return {
          id: 1,
          id_proveedor: 1,
          proveedor: "Amazon",
          obs: "11121607 - Papel bond",
          id_unidad: 1,
          unidad: "Unidad",
          id_presentacion: 1,
          presentacion: "Resma",
          precio: 3.0,
          presentaciones: [
            { id: 1, name: "Unidad" },
            { id: 2, name: "Caja" },
          ],
          obs_foto: [],
        };
      },
    },
    title: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    obs_imagenes: [],
    readyToRender: false,
  }),
  watch: {
    obs() {
      this.getPresentationImages();
    },
  },
  methods: {
    getPresentationImages() {
      const obs_foto_ids = this.obs.obs_foto;
      let promises = [];
      let images = [];
      this.obs_imagenes = [];
      for (let i = 0; i < obs_foto_ids.length; i++) {
        promises.push(
          this.services.AgreementMarco.getImagenesPresentacion(
            obs_foto_ids[i].id
          ).then((response) => {
            let files = response?.data;
            const reader = new FileReader();
            reader.onload = (files) => {
              images.push({
                id: i + 1,
                url: files.target.result,
              });
            };
            reader.readAsDataURL(files);
          })
        );
      }
      Promise.all(promises).then(() => {
        setTimeout(() => {
          this.obs_imagenes = images;
        }, 200);
      });
    },
  },
  created() {
    if (this.obs && this.obs.obs_foto) this.getPresentationImages();
  },
};
</script>

<style lang="scss" scoped>
.caracteristica-producto-titulo,
.caracteristica-producto-subtitulo {
  padding: 0px 12px 0px 12px;
  margin: 0px;
  p {
    margin: 0px 0px 5px 0px;
  }
}
.caracteristica-producto-titulo p {
  color: #697185;
}
</style>
